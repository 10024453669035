<div class="container content">
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <form name="confirmform" role="form" class="formpage">
          <div class="reg-header" [hidden]="message !== ''">
              <h1 class="reg-header">Thank You.</h1>
              <label>We sent email to your Email Address</label>
              <label>Please confirm your email address to access Bizns Tool.</label>
              <a class="btn-c btn-bordered pull-right" href="/home">Home</a>
          </div>
          <div [hidden]="message == ''" class="alert alert-danger">
              {{message}}
          </div>
      </form>
    </div>
  </div>
</div>

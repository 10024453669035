<div>

  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>Estimates & Change Orders</h4>
          <h1>Manage Construction Project Bid Estimates and Ongoing Change Orders</h1>
          <p>Organize Labor, Material and Equipment for the Project </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <!--  Team Blcoks  -->
    <div class="row team-v7 no-gutter equal-height-columns">
      <div class="col-md-6 col-md-push-6">
        <div class="dp-table text-center">
          <div class="equal-height-column dp-table-cell team-v7-in home-lightblock">
            <h1 class="team-v7-name">Bizns Tool allows you to quickly and easily Estimates / Quotes.</h1>

            <div class="margin-bottom-20 margin-top-20" style="line-height:24px;">
              <p>Once an Estimate / Quote is accepted as a bid, it can easily be converted to a Project and converted to a Contact for the General Contractor.</p>
              <p>With the Bizns Tool, creating quotes for bid invites has been made seamless. It’s very easy to keep the project managers and general contractors up to date.</p>
              <p>Keep track of frequent changes and updates, which are quite common with construction projects.</p>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-6 col-md-pull-6 team-v7-img">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/estimates.jpg" alt="Automation Project Bids Seamlessly" />
      </div>
    </div>
    <!--  End Team Blcoks  -->
    <!--  Team Blcoks  -->
    <div class="row team-v7 no-gutter equal-height-columns">
      <div class="col-md-6">
        <div class="dp-table text-center">
          <div class="equal-height-column dp-table-cell team-v7-in home-lightblock">
            <h1 class="team-v7-name">Manage Change Orders for Ongoing Project Changes.</h1>
            <div class="margin-bottom-20 margin-top-20">
              <p>The Bizns Tool will make the approval of Change Orders seamless and fast. It will also monitor all the change orders of the construction project.</p>
              <p>Create custom Change Orders templates for the Project and the General Contractor. It's simple and easy to create a customized Change Order template.</p>
              <p>Monitor your approved COs as they pertain to the project’s costs to keep track of the project budget and expenses.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 team-v7-img">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/changeorders.jpg" alt="Estimates and Change Orders with Bizns Tool" />
      </div>
    </div>
    <!--  End Team Blcoks  -->
  </div>
</div>

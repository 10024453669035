<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-20">
          <h4>Industries</h4>
          <h1>Focus on Speciality Trade Contractor Construction Industry</h1>
          <p>We support list of Speciality Trade Contractors</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row home-lightblock">
      <div class="col-sm-12 text-center">
        <div class="row margin-bottom-40">
          <div class="col-sm-12">
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Electrical</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Plumbing</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Mechanical</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>HVAC</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Foundation and structure</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Framing</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Glass and Glazing</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Siding</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Dry wall and Insulation</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Painting</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Carpentry</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Other Specialty</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container content">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
      <form name="forgotform" role="form" class="formpage" style="height:50%;padding-top:60px;" *ngIf="!itemVisible" novalidate>
        <div class="reg-header">
          <h1>Thank you</h1>
          <label>We sent email to <a>{{option.Name}}</a></label>
          <br />
          <br />
          <label>Please confirm email address to reset password</label>
        </div>
      </form>

      <form name="forgetForm" role="form" class="formpage" *ngIf="itemVisible" style="margin-bottom:300px" novalidate #forgetForm="ngForm" (ngSubmit)="onSubmit(forgetForm)">
        <div class="reg-header">
          <h1>Forgot Password</h1>
        </div>
        <div class="input-group margin-bottom-20">
          <span class="input-group-addon"><i class="fa fa-mail-reply"></i></span>
          <input type="email" placeholder="Email Id" name="email" class="form-control" required [(ngModel)]="option.Name" #email="ngModel">
        </div>
        <p class="color-red" *ngIf="forgetForm.submitted && email.invalid">Please enter valid EmailId.</p>
        <div class="row">
          <div class="col-md-12">
            <button type="submit" class="btn-c pull-right">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div><!--/row-->
</div>

<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>User Management</h4>
          <h1>Manage Business with Effective Team Collaboration</h1>
          <p>Team management has never been easier with the assistance of the Bizns Tool.</p>
        </div>
      </div>
    </div>
  </div>



  <div class="container-fluid">
    <div class="row margin-bottom-20">
      <div class="col-md-4">
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="service">
            <div class="desc">
              <h4 class="color-dark-blue">Add Users and Control Access</h4>
              <p>Working with a partner or a team? Bizns Tool lets you customize user access to maintain control and protect sensitive information.</p>
              <p>It will also show your collaborators assigned items when they are logged in.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="service">

            <div class="desc">
              <h4 class="color-dark-blue">Role-Based Team Management</h4>
              <p>
                The Bizns Tool provides you and your team with an easy way to stay on team tasks. Bizns tool lets you team feature like
              </p>
              <h5>Assign roles to Project team users based on their job functions.</h5>
              <h5>Enable/disable access to specific  modules</h5>
              <h5>Create, edit, update and delete permissions anytime.</h5>
            </div>

          </div>
        </div>

      </div>
      <div class="col-md-4">
      </div>

    </div>

    <div class="row">
      <div class="col-sm-offset-3 col-sm-6">
        <img class="img-responsive home-lightbg-img" src="/assets/images/bizns/teamrole.jpg" alt="Manage Effective Team Collaboration with Bizns Tool" />
      </div>
    </div>

  </div>

</div>

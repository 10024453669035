<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>Project Scheduling</h4>
          <h1>Manage Project Resource and Task Scheduling</h1>
          <p>Construction projects consume a lot of resources; manage your resource with the Bizns Tool Software.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row margin-bottom-20">
      <div class="col-md-4">
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="service">
            <div class="desc">
              <h4 class="color-dark-blue">Project Task Scheduling</h4>
              <p>This is where the Bizns Tool stands out. Simple yet powerful, it allows you to view the construction project management schedules for all tasks related to resources across the entire project. </p>
              <p>Make changes to resource task schedules online and update task status with percentage completed. It gives you a 360-degree view of the project tasks and the resources assigned.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="service">

            <div class="desc">
              <h4 class="color-dark-blue">Manage Resource more Effectively</h4>
              <p>
                Maintain uniformity and save time with convenient resource assigned tasks, filter all the tasks associated with resources, and manage pending and ongoing tasks.
              </p>
              <p>The Resource 360 view provides a quick glance so you know exactly what back-end and on-field resources are allocated to each task.</p>
            </div>

          </div>
        </div>

      </div>
      <div class="col-md-4">
      </div>

    </div>

    <div class="row">
      <div class="col-sm-offset-3 col-sm-6">
        <img class="img-responsive home-lightbg-img" src="/assets/images/bizns/scheduler.jpg" alt="Create Project and Resource Scheduling with Bizns Tool" />
      </div>
    </div>

  </div>


</div>

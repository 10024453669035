<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-20">
          <h4>Project Management</h4>
          <h1>Bizns (Tool) is Online Construction Software for Subcontractors</h1>
          <p>About Us</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row home-lightblock">
      <div class="col-sm-12 text-center">
        <div class="row margin-bottom-40">
          <div class="col-sm-12">
            <div class="row">
              <div class="service">
                <div class="desc">
                  <h4 class="color-dark-blue">Built by Construction Specialist for Subcontractors</h4>
                  <p>Bizns Tool is well planned and designed for Construction Subcontractors, Its well analyzed to fit daily subcontractor needs. Things like working with . </p>
                  <h5>Bid Invites for Project Coordinator.</h5>
                  <h5>Easy and simple Estimator Tools.</h5>
                  <h5>360 view of Projects for Project Managers</h5>
                  <h5>Accounting follow-up notifications for pending Invoices</h5>
                  <h5>Project Executive to monitor multiple ongoing projects</h5>
                  <h5>Owners Reports and Analytics </h5>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="service">
                <div class="desc">
                  <h4 class="color-dark-blue">About Us</h4>
                  <p>
                    Located at Sunny Southern California - Long Beach City. Call us for any information on our Software
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <a href="mailto:support@biznstool.com" class="email">support@biznstool.com</a>
              </div>
            </div>

            <!--<div class="row">
              <div class="col-md-12">
                <h4>Call Us :  (562)-999-4549</h4>
              </div>
            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container content">
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <form name="confirmform" role="form" class="formpage">
        <div class="reg-header">
          <h1 class="reg-header">Thank You.</h1>
          <label>We sent email to your Email Address</label>
          <label>Please confirm your email address to access Bizns Tool.</label>
          <div><a class="btn-c btn-bordered pull-right" href="/login">Login</a></div>
        </div>
      </form>
    </div>
  </div>
</div>

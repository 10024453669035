<div>

  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>RFIs & Submittals</h4>
          <h1>Communicate Efficiently with General Contractor with RFIs</h1>
          <p>Create and Respond to RFI online with a Customized Template for your Construction Project</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <!--  Team Blcoks  -->
    <div class="row team-v7 no-gutter equal-height-columns">
      <div class="col-md-6">
        <div class="dp-table text-center">
          <div class="equal-height-column dp-table-cell team-v7-in home-lightblock">

            <h1 class="team-v7-name">RFI (Request for Information)</h1>
            <div class="margin-bottom-20 margin-top-20">
              <p>The Bizns Tool includes customized online RFI templates (as per General Contractor Standards), to which you can add your company logo, header, footer and additional information as per business needs.</p>
              <p> Simple and easy preview of the RFI templates enabling the creation of a well-documented RFI. One click to send the RFI as an email with a PDF attachment and more. </p>
              <p>Record all your RFIs for the project and keep everything organized in ‘the cloud’. No more losing or misplacing your RFI request or replies. Your project’s RFIs are done with one click. </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 team-v7-img">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/rfi.jpg" alt="Manage Company Accounts with Bizns Tool" />
      </div>
    </div>
    <!--  End Team Blcoks  -->
    <!--  Team Blcoks  -->
    <div class="row team-v7 no-gutter equal-height-columns">
      <div class="col-md-6 col-md-push-6">
        <div class="dp-table text-center">
          <div class="equal-height-column dp-table-cell team-v7-in home-lightblock">
            <h1 class="team-v7-name">Manage Project Submittals</h1>
            <div class="margin-bottom-20 margin-top-20">
              <p>
                The Bizns Tool helps organize all requested submittals for the project. Any team member can manage submittals online. Never again miss a requested submittal or a response to submittals.
              </p>
              <p>Record and filter the project’s submittals by the due date, assigned to, GC, or manual search.</p>
              <p>Monitor all submittals to the General Contractor. Maintain a record of all requests from the GC for submittals.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-md-pull-6 team-v7-img">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/submittals.jpg" alt="Effectively Manage Project Contacts with Bizns Tool" />
      </div>
    </div>
    <!--  End Team Blcoks  -->
  </div>
</div>

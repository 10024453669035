<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-20">
          <h4>Reports</h4>
          <h1>Interactive Reports and Charts</h1>
          <p>
            Receive on-demand database reports and sales figures that will help you see a complete picture of your business, individual companies, the performance of your team members, and more. The Bizns Tool lets you choose from several reporting options:
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row home-lightblock">
      <div class="col-sm-12 text-center">
        <div class="row margin-bottom-40">
          <div class="col-sm-12">
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Leads / Bid Invites Reports</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Contractor Summary Reports</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Opportunity Reports</h4>
            </div>
			<div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Projects Performance Reports</h4>
            </div>
            <div class="row">
              <h4><i class="fa fa-circle service-icon color-dark-blue"></i>Financial Reports</h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-3 col-sm-6">
            <img class="img-responsive home-lightbg-img" src="/assets/images/bizns/analytics.jpg" alt="Interactive Reports and Charts for Projects Charges and Expenses" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <!-- === Purchase Block === -->
  <div class="purchase img-responsive">
    <div class="margin-top-20 margin-bottom-20 container overflow-h">
      <div class="row margin-top-20">
        <!--  animated fadeInLeft -->
        <div class="col-sm-12">
          <h1>Simple Construction Software</h1>
          <h1> for <u>Subcontractors & Specialty Trade Contractors</u></h1>
          <h4 class="text-center margin-top-20"><b>Electrical . Plumbing . Mechanical . HVAC . Dry wall . Painting . Flooring . Carpentry . Foundation</b></h4>
          <h1>Track Bid Invites from General Contractor and more...</h1>

          <!--<p class="margin-top-20">
        Manage complex construction projects with a clever construction project management software
    </p>
    <p>
        No more juggling multiple software. Manage any project, big or small, in a single simple system.
    </p>
    <p>
        A number of advantages and features are associated with the project that distinguishes Binz tool from other tools in the market.
    </p>-->

          <div class="row margin-top-20 margin-bottom-10">

            <div class="col-sm-2">

            </div>
            <div class="col-sm-8">

              <div class="row">
                <div class="col-md-4">
                  <h4><i class="fa fa-user service-icon"></i>CRM</h4>
                </div>

                <div class="col-md-4">
                  <h4><i class="fa fa-comments service-icon"></i>Estimates / Quotes</h4>
                </div>

                <div class="col-md-4">
                  <h4><i class="fa fa-caret-square-o-down service-icon"></i>Leads / Bids</h4>
                </div>

              </div>
              <div class="row">
                <div class="col-md-4">
                  <h4><i class="fa fa-info service-icon"></i>RFIs</h4>
                </div>
                <div class="col-md-4">
                  <h4><i class="fa fa-folder service-icon"></i>Change Orders</h4>
                </div>
                <div class="col-md-4">
                  <h4><i class="fa fa-exchange service-icon"></i>Submittals</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <h4><i class="fa fa-users service-icon"></i>Schedule Projects</h4>
                </div>
                <div class="col-md-4">
                  <h4><i class="fa fa-money service-icon"></i>Invoice / Payments</h4>
                </div>
                <div class="col-md-4">
                  <h4><i class="fa fa-filter service-icon"></i>Opportunity</h4>
                </div>
              </div>

            </div>
            <div class="col-sm-2">

            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <!-- animated fadeInRight -->
        <div class="col-md-12 btn-buy">
          <!--<a href="/signup" class="btn-c btn-c-lg"><i class="fa fa-cloud-download"></i>Get Started! <br /> <small>1 User FREE</small></a>-->
          <a href="/signup" class="btn-c btn-c-lg"><i class="fa fa-cloud-download"></i>Get Started! <br /> <small>Try 14 Days FREE</small></a>
        </div>
      </div>
    </div>
  </div>
  <!--  End Purchase Block  -->

  <div class="container-fluid">
    <div class="row home-darkblock">
      <div class="col-sm-12 margin-top-20 text-center">
        <div class="row">
          <h1>Customize, Create and Send Estimates Online</h1>
          <p>When you log in, you'll get a quick snapshot of everything that matters. See what's in your project pipeline.</p>
          <p>Stay on top of important dates (change orders, RFI's, submittals and deadlines). It's Simple and Easy</p>
        </div>
        <!--<div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-3">
            <div class="pricing-head">
              <h3>
                <span class="label label-success" style="padding:5%;text-align:center">
                  <b style="font-size:16px">$</b>
                  <strong>0</strong>
                </span>
                <span class="label-success">
                  <em>
                    14-Days free
                  </em>
                </span>
              </h3>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="pricing-head">
              <h3>
                <span class="label label-success" style="padding:5%;text-align:center">
                  <b style="font-size:16px">$</b>
                  <strong>12</strong>
                </span>
                <span class="label-success">
                  <em>
                    per user/month
                  </em>
                </span>
              </h3>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>-->
        <!--<div class="row">
          <a href='https://www.capterra.com/reviews/161992/Bizns-Tool-CRM?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'>
            <img border='0' src='https://assets.capterra.com/badge/e4744b025bfb4fa6f7b3167b5743311d.png?v=2113237&p=161992' />
          </a>
        </div>-->
        <div class="row">
          <div class="col-sm-offset-3 col-sm-6">
            <img class="img-responsive home-darkbg-img" src="/assets/images/bizns/dashboard.png" alt="Construction Dashboard for Project pipeline" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row margin-top-20 margin-bottom-20 home-lightblock">

      <div class="col-sm-12">
        <div class="row text-center" style="padding:20px">
          <div class="col-sm-12">
            <h1>Automate your Projects and Jobs from Bid Invities to Payments (AIA Invoicing)</h1>
            <p>Turn every bid invite you receive into an opportunity in seconds. Just log in and enter the details from your computer or mobile web browser. </p>
            <p>No downloads required. Keep your business data up to date no matter where you go or when opportunity strikes. </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!--<p align="center"><a href="/signup" class="btn-c btn-c-lg text-center"><i class="fa fa-cloud-download"></i>  No Credit Card Required</a></p>-->
            <p align="center"><a href="/signup" class="btn-c btn-c-lg text-center"><i class="fa fa-cloud-download"></i>  No Credit Card Required</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid">
    <div class="row home-darkblock">
      <div class="col-sm-12 margin-top-20 text-center">
        <div class="row margin-bottom-10">
          <h1>Tool for Specialty Trade Contractors</h1>
          <p>Try our 1-Week free trial and check out and see how it works for you. One Simple Plan Cost-Effective Plan.</p>
        </div>
        <div class="row margin-bottom-20">

          <div class="col-sm-2"></div>

          <div class="col-sm-8">
            <div class="row">
              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Electrical</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Plumbing</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Mechanical</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>HVAC</h4>
              </div>

            </div>
            <div class="row">
              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Foundation and Structure</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Framing</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Glass and Glazing</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Siding</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Dry wall and Insulation</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Painting</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Carpentry</h4>
              </div>

              <div class="col-md-3">
                <h4><i class="fa fa-circle service-icon"></i>Other Specialty</h4>
              </div>
            </div>

          </div>
          <div class="col-sm-2"></div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <!--<p align="center"><a href="/signup" class="btn-c btn-c-lg text-center"><i class="fa fa-cloud-download"></i>  <b>1</b> - User FREE</a></p>-->
            <p align="center"><a href="/signup" class="btn-c btn-c-lg text-center"><i class="fa fa-cloud-download"></i> Try 14 Days FREE</a></p>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- === Content Part === -->
  <div class="container content-sm">
    <div class="row home-lightblock">
      <div class="col-md-12">
        <div><h1 class="color-dark-blue text-center">Key Bizns Tool Features</h1></div>
        <!--  Service Blocks  -->
        <div class="row margin-bottom-30">

          <div class="col-md-4">
            <div class="service">

              <div class="desc">
                <h4><i class="fa fa-stack-exchange service-icon"></i>Supports Industries</h4>
                <p>Bizns Tool Software best fits for list of speciality trade contractors like Electrician, Foundation and structure, Steel Structural, Framing, Masonry, Glass and Glazing and much more.</p>
                <a href="/sub/speciality-trades" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-caret-square-o-down service-icon"></i>Leads / Bids</h4>
                <p>Requesting bids and managing proposals is overwhelming if done using emails. Bizns Tools for construction subcontractors helps organize and participate in the projects.</p>
                <a href="/sub/manage-bids" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-comments service-icon"></i>Estimate / Change Orders</h4>
                <p>With Bizns Tool, creating quotes for bid invites has been made seamless. It makes it easy to keep the project managers and general contractors up to date.</p>
                <a href="/sub/estimates-change-orders" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-30">

          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-bank service-icon"></i>Charges / Expenses</h4>
                <p>All charges, be it the Initial Approval Estimate or ongoing approved change orders. Bizns Tool gives a clear picture about the charges of the project.</p>
                <a href="/sub/charges-expenses" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-users service-icon"></i>Scheduling / Daily Reports</h4>
                <p>This is where Bizns Tool stands out. Simple yet powerful, it allows you to manage daily project tasks and resource scheduling.</p>
                <a href="/sub/scheduling" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-money service-icon"></i>Invoices/ Payments</h4>
                <p>You never have to worry about missing payments again. With Bizns Tool, it enables you to keep track of project invoices and payments.</p>
                <a href="/sub/invoice-payments" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-30">
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-info service-icon"></i>RFIs / Submittals</h4>
                <p>Managing RFIs made simple with Bizns Tool, send RFI with integrated email and customized forms. Also send submittals to owners and architects.</p>
                <a href="/sub/rfis-submittals" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-list-alt service-icon"></i>Integrations</h4>
                <p>Operate more efficiently by connecting Bizns Tool to other applications you use every day, such as QuickBooks Online, MailChimp, Gmail and Outlook.</p>
                <a href="/sub/app-integrations" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service">
              <div class="desc">
                <h4><i class="fa fa-flag-checkered service-icon"></i>Reports</h4>
                <p>Bizns Tool reports provide insights that will allow you to make smart business decisions. Bizns supports daily, weekly, monthly and annual reporting.</p>
                <a href="/sub/business-reports" class="btn-sm btn-success text-center">More Info</a>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">

      <form name="requestForm" role="form" class="formpage" style="height:50%;padding-top:100px;" *ngIf="!itemVisible">
        <div class="reg-header">
          <h1>Thank you</h1>
          <label>We did receive request for FREE 2 weeks account setup.</label>
          <br />
          <br />
          <label>We sent a confirmation to <a>{{registrationData.userName}}. We will get back to you soon with account setup.</a></label>
        </div>
      </form>

      <form name="requestForm" role="form" class="formpage" *ngIf="itemVisible" novalidate #requestForm="ngForm" (ngSubmit)="onSubmit(requestForm)">

        <div class="reg-header">
          <h1>Request for FREE 14 days account setup</h1>
        </div>

        <label class="signin-label">Name<span class="color-red">*</span></label>
        <input type="text" name="name" class="form-control margin-bottom-20" required [(ngModel)]="registrationData.name" #name="ngModel">
        <p *ngIf="requestForm.submitted && name.invalid" class="color-red">Please Enter Name.</p>

        <label class="signin-label">Email Id <span class="color-red">*</span></label>
        <input type="email" name="email" class="form-control margin-bottom-20" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required [(ngModel)]="registrationData.userName" #email="ngModel">
        <p class="color-red" *ngIf="requestForm.submitted && email.invalid">Please enter valid EmailId.</p>

        <label class="signin-label">Phone Number<span class="color-red">*</span></label>
        <input type="text" name="phoneNumber" class="form-control margin-bottom-20" required [(ngModel)]="registrationData.phoneNumber" placeholder="000-000-0000" pattern="^\d{3}-\d{3}-\d{4}$" #phoneNumber="ngModel">
        <p *ngIf="requestForm.submitted && phoneNumber.invalid" class="color-red">Please Enter Phone Number 000-000-0000.</p>

        <label class="signin-label">Company Name<span class="color-red">*</span></label>
        <input type="text" name="company" class="form-control margin-bottom-20" required [(ngModel)]="registrationData.companyName" #companyName="ngModel">
        <p *ngIf="requestForm.submitted && companyName.invalid" class="color-red">Please Enter Company Name.</p>

        <label class="signin-label">Contractor Type<span class="color-red">*</span></label>
        <select name="industryType" class="form-control margin-bottom-20" required [(ngModel)]="registrationData.industryType" #industryType="ngModel">
          <option value="">Select Type</option>
          <option *ngFor="let opt of IndustryTypes" [ngValue]="opt.Name">{{opt.Name}}</option>
        </select>
        <p *ngIf="requestForm.submitted && industryType.invalid " class="color-red">Please Select Contractor Type.</p>

        <label class="signin-label">Comments<span class="color-red"></span></label>
        <textarea name="description" class="form-control margin-bottom-20" required [(ngModel)]="registrationData.description" #description="ngModel"> </textarea>
       
        <div class="row">
          <div class="col-sm-12">
            <label class="signin-label">Select items interested in software <span class="color-red">*</span></label>

            <div class="row margin-bottom-20">
              <div class="col-sm-12">
                <span *ngFor="let opt of Features">
                  <button type="button" style="margin:2px" class="btn" [ngClass]="opt.isSelect ? 'btn-success' : 'btn-default' " (click)="toggleItem(opt)"><b>{{opt.Name}}</b></button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-sm-12 text-right">
            <button type="submit" class="btn-c pull-right" value="Request Demo">
              <span *ngIf="!isBusy">Request Demo</span>
              <span *ngIf="isBusy">Requesting...</span>
            </button>
          </div>
        </div>

        <div [hidden]="message == ''" [ngClass]="{'alert alert-success': savedSuccessfully, 'alert alert-danger': !savedSuccessfully}">
          {{message}}
        </div>
      </form>
    </div>
  </div>
</div>

<div class="container content">

  <div class="row text-center home-lightblock">
    <div class="col-sm-12">
      <h4>Pricing Plans</h4>
      <h1 class="color-dark-blue">Simple Plan For Subcontractors & Specialty Trade Contractors</h1>
      <p>Budget project management software for Subcontractor & Specialty Trade Contractors.</p>
    </div>
  </div>

  <!--<div class="row home-lightblock">
    <div class="col-sm-12 margin-top-20 margin-bottom-20">
      <p align="center"><a href="" class="btn-c btn-c-lg text-center">Request Demo - (562)-999-4549</a></p>
    </div>
  </div>-->
  
  <!--  Pricing Mega v2  -->
  <div class="row no-space-pricing pricing-mega-v2">
    <!--<div class="col-md-6 col-sm-6 block">
      <div class="pricing">
        <div class="pricing-head">
          <h3>
            Free
            <span class="label label-success" style="padding:50px">
              <b style="font-size:24px">$</b>
              <strong>0</strong>
            </span>
            <span class="label label-success">
              <em>
                1 User FREE
              </em>
            </span>
          </h3>
        </div>
      </div>
    </div>-->
    <div class="col-md-12 col-sm-12 block">
      <div class="pricing">
        <div class="pricing-head">
          <h3>
            Standard
            <span class="label label-success" style="padding:50px">
              <b style="font-size:24px">$</b>
              <strong>28</strong>

            </span>
            <span class="label label-success">
              <em>
                 per user/month
                (billed yearly)
              </em>
            </span>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <!--  End Pricing Mega v2  -->

  <div class="row home-lightblock">
    <div class="col-sm-12 margin-top-20 margin-bottom-20">
      <!--<p align="center"><a href="/signup" class="btn-c btn-c-lg text-center"><i class="fa fa-cloud-download"></i> <b>1</b> User FREE </a></p>-->
      <p align="center"><a href="/signup" class="btn-c btn-c-lg text-center"><i class="fa fa-cloud-download"></i>  Try 14 Days FREE</a></p>
    </div>
  </div>

  <div class="row home-lightblock">
    <div class="col-sm-12 margin-top-20 margin-bottom-20">

      <h1 class="text-center color-dark-blue" >Frequently Asked Questions</h1>

      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4>
                When will I be billed?
              </h4>
              <p>
                After your free trial, if you chose to a paid plan, we will bill you based on the number of users and the period you have chosen (annual or monthly).
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h4>
                What payment methods do you accept?
              </h4>
              <p>
                We accept Visa, MasterCard, American Express and Discover debit and credit cards.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h4>
                Can I cancel my account and get a refund?
              </h4>
              <p>
                If you have chosen monthly billing, you may request a cancellation on the last day of your billing cycle. We do not offer refunds.
              </p>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12">
              <h4>
                Can I upgrade, downgrade or cancel anytime?
              </h4>
              <p>
                You may upgrade at any time. You may request a downgrade at the end of your billing cycle. Please note that we do not offer refunds.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h4>
                Will I be charged for inactive users?
              </h4>
              <p>
                You will be charged for the total number of users that you register, whether the user is active or inactive.
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">

              <h4>
                Can I change my payment method from annual to monthly?
              </h4>
              <p>
                You may change from monthly to annual billing at any time. To change from annual to monthly billing, you must wait until the end of the annual billing cycle.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div><!-- /container -->
<!-- === End Content Part === -->

<div class="container content">
  <div class="row">
    <div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
        <form class="formpage margin-bottom-100" name="resetForm" novalidate autocomplete="off" (ngSubmit)="onSubmit(resetForm)" #resetForm="ngForm">
            <div class="reg-header">
                <h1>Reset Password</h1>
            </div>
            <div class="input-group margin-bottom-20">
                <span class="input-group-addon"><i class="fa fa-user"></i></span>
                <input type="email" placeholder="Email" name="email" class="form-control" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required [(ngModel)]="reset.EmailId" #email="ngModel">
            </div>
            <p class="color-red" *ngIf="resetForm.submitted && !email.valid">Please enter valid EmailId.</p>
            <div class="col-sm-12">
                <span style="font-size:10px">[Use at least 8 characters.Should contain at least 1 special character except '&'.]</span>
            </div>
            <label class="signin-label"> <span class="color-red">*</span> New Password</label>
            <div class="input-group margin-bottom-20">
                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input type="password" name="new password" placeholder="New Password" class="form-control" required [(ngModel)]="reset.Password" autocomplete="off" #password="ngModel">
            </div>
            <p *ngIf="resetForm.submitted && password.invalid && password.pristine" class="color-red">Please enter password.</p>
            <label class="signin-label"> <span class="color-red">*</span> Retype password</label>
            <div class="input-group margin-bottom-20">
                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input type="password" placeholder="Confirm Password" class="form-control margin-bottom-20" required [(ngModel)]="reset.ConfirmPassword" name="verify" #confirmpassword="ngModel">
            </div>
            <div class="row text-center">
                <span class="color-red" [hidden]="(reset.Password == reset.ConfirmPassword) || (reset.ConfirmPassword == '')">Password need to match</span>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn-c pull-right" type="submit">Reset</button>
                </div>
            </div>
            <div [hidden]="message == ''" class="alert alert-danger">
                {{message}}
            </div>
        </form>
    </div>
  </div><!--/row-->
</div>

<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>Integrations</h4>
          <h1>Popular software integrated at once place</h1>
          <p>Integrate Bizns Tool with your existing popular Apps</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row margin-bottom-20">
      <div class="col-md-4">
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="service">
            <div class="desc">
              <h4><img src="/assets/images/quickbooks.jpe" alt="Integrate Bizns Tool Subcontractor Software with QuickBooks Online" /> QuickBooks Online</h4>
              <p>QuickBooks Online integration makes payment processing easy. Bizns Tool invoices can be added to QuickBooks with a single click.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="service">
            <div class="desc">
              <h4><img src="/assets/images/googlelogo.png" alt="Integrate Google Gmail with Bizns Tool" /> Gmail</h4>
              <p>Gmail integration sends your Bizns Tool email messages through the world’s most popular email provider.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="service">
            <div class="desc">
              <h4><img src="/assets/images/outlook.png" alt="Integrate Bizns Tool with Microsoft Office 365 / Outlook" /> Microsoft Office 365 / Outlook</h4>
              <p>Send email and synchronize your Bizns Tool calendar and contacts with  Office 365 / Outlook.</p>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="service">
            <div class="desc">
              <h4><img src="/assets/images/smtplogo.png" alt="Integrate Bizns Tool with Microsoft Outlook 2016" /> SMTP</h4>
              <p>Send email and synchronize your Bizns Tool calendar and contacts with SMTP Settings.</p>
            </div>
          </div>
        </div>

        <!--  <div class="row">
            <div class="service">
                <div class="desc">
                    <h4><i class="fa fa-circle service-icon"></i>Facebook - Connet Social</h4>
                    <p>Goal of CRM is to improving and managing business relationships with customers, assisting in customer retention and driving sales growth.</p>
                </div>
            </div>
        </div>  -->



      </div>
      <div class="col-md-4">
      </div>

    </div>
  </div>
</div>

<div class="footer-v1">

  <div class="footer">
    <div class="container">
      <div class="row">
        <!-- About -->
        <div class="col-md-3 md-margin-bottom-40">
          <a href="/bizns"><img id="logo-footer" class="footer-logo" src="/assets/images/logo-footer.png" alt="Subcontractor Project Tool for Construction Industry"></a>
        </div><!--/col-md-3-->
        <!-- End About -->
        <!-- Latest -->
        <!-- End Latest -->
        <!-- Link List -->
        <div class="col-md-3 md-margin-bottom-40">
          <div class="headline headline-footer"><h2>Features</h2></div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/manage-bids">Bid Invites</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/rfis-submittals">RFIs / Submittals</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/estimates-change-orders">Estimates / Change Orders</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/charges-expenses">Project Charges / Expenses</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/invoice-payments">Invoice / Payments</a><i class="fa fa-angle-right"></i>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <a href="/sub/scheduling">Team Management</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/app-integrations">Integration</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a routerLink="/sub/business-reports">Sales Reporting</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div><!--/col-md-3-->

        <div class="col-md-3 md-margin-bottom-40">
          <div class="headline headline-footer"><h2>Support</h2></div>
          <div class="row">
            <div class="col-md-12">
              <a target="_blank" href="https://support.biznstool.com/started">Just Started</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a target="_blank" href="https://support.biznstool.com//knowledge">Knowledge Base</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a target="_blank" href="https://support.biznstool.com/faq">Faq</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <a target="_blank" href="https://support.biznstool.com/video">Video Tutorials</a><i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div><!--/col-md-3-->
        <!-- End Link List -->
        <!-- Address -->
        <div class="col-md-3 md-margin-bottom-40">
          <div class="headline headline-footer"><h2>Reach Us</h2></div>
          <div class="row">
            <div class="col-md-12">
              <div class="footer-city">Long Beach, CA</div>
            </div>
          </div>
          <!--<div class="row">
              <div class="col-md-12">
                  <a href="">Contact</a><i class="fa fa-angle-right"></i>
              </div>
          </div>-->

          <div class="row">
            <div class="col-md-12">
              <div class="footer-city">
                <h4 style="color:#fff"></h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <a href="mailto:support@biznstool.com" class="email">support@biznstool.com</a>
            </div>
          </div>

          <div class="row">
            <!-- Social Links -->
            <div class="col-md-12">
              <ul class="footer-socials list-inline">
                <li>
                  <a href="https://www.facebook.com/biznstool" target="_blank" class="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Facebook">
                    <i class="fa fa-facebook fa-2x"></i>
                  </a>
                </li>

                <li>
                  <a href="https://plus.google.com/113266486617480548189" target="_blank" class="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Google Plus">
                    <i class="fa fa-google-plus fa-2x"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/bizns-tool-crm" target="_blank" class="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Linkedin">
                    <i class="fa fa-linkedin fa-2x"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCRjJzAMefGeZ7c5-Ls64KJA" target="_blank" class="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="YouTube">
                    <i class="fa fa-youtube fa-2x"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/Biznstool_sub" target="_blank" class="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="YouTube">
                    <i class="fa fa-twitter fa-2x"></i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- End Social Links -->
          </div>

          <!-- End Address -->
        </div>
      </div>
    </div><!--/footer-->

    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>
                <span id="copyright">2019</span> &copy; All Rights Reserved.
                <a href="" class="signin-a" (click)="openTermsModalDlg($event)">Terms and Conditions</a> | <a href="" (click)="openPrivacyModalDlg($event)">Privacy Policy</a>
            </p>
          </div>

        </div>
      </div>
    </div><!--/copyright-->
  </div>
  <!--=== End Footer Version 1 ===-->
</div>

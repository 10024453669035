<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>Project Charges & Expenses</h4>
          <h1>Gain Financial and Budget Insights for Construction Project Management</h1>
          <p>Know where are your revenue and expenses are for each construction project</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">


    <div class="row margin-bottom-20">
      <div class="col-md-4">
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="service">

            <div class="desc">
              <h4 class="color-dark-blue">Organize all Project Charges</h4>
              <p>
                All expenses, whether it’s the initial approval estimates or the ongoing approved change orders, are classified as Project Charges.
              </p>
              <p>The Bizns Tool provides a clear picture of all Project Charges. It allows you to monitor project expenses, including Labor, Material, and Equipment. </p>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="service">

            <div class="desc">
              <h4 class="color-dark-blue">Keep Track of all Project Expenses</h4>
              <p>It allows you to track all expenses and compare them to the charges for the project.</p>
              <p>No longer will you be ‘in the dark’ about project expenses. You will be able to see all your project payments and expenses, as well as being able to monitor your project margin and expenses.</p>
            </div>

          </div>
        </div>

      </div>
      <div class="col-md-4">
      </div>

    </div>

    <div class="row">
      <div class="col-sm-offset-3 col-sm-6">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/projectcharges.jpg" alt="Create Estimates and Invoices with Customer Relationship Management" />
      </div>
    </div>
  </div>
</div>

<div>

  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>Manage Leads & Bid Invites</h4>
          <h1>Simple Construction Project Management Software from Bid Invites to Payments</h1>
          <p>You are a professional. Why not manage your construction project like one? Get organized with Bizns Tool.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <!--  Team Blcoks  -->
    <div class="row team-v7 no-gutter equal-height-columns">
      <div class="col-md-6 col-md-push-6">
        <div class="dp-table text-center">
          <div class="equal-height-column dp-table-cell team-v7-in home-lightblock">
            <h1 class="team-v7-name">Manage Leads and Bid Invites, Contacts Anywhere You Go</h1>

            <div class="margin-bottom-20 margin-top-20" style="line-height:24px;">
              <p>Requesting bids and managing proposals is overwhelming if done using emails. Bizns Tools for construction subcontractors helps organize and participate in the process with an invitation from General Contractors to Sub to subcontractors.</p>
              <p>
                Cloud software for construction provides a single point to manage the bids and projects, Bid invites to the payments enables to manage the project at efficient scales.
              </p>
              <p>Using the Bizns Tool Software will make the management of bids much easier should any issues arise during the project and the software will provide effective solutions</p>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-6 col-md-pull-6 team-v7-img">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/salelead.jpg" alt="Construction Project Management Software" />
      </div>
    </div>
    <!--  End Team Blcoks  -->
    <!--  Team Blcoks  -->
    <div class="row team-v7 no-gutter equal-height-columns">
      <div class="col-md-6">
        <div class="dp-table text-center">
          <div class="equal-height-column dp-table-cell team-v7-in home-lightblock">
            <h1 class="team-v7-name">Closely Monitor Bids and Opportunity</h1>
            <div class="margin-bottom-20 margin-top-20">
              <p>Send Quotes / Estimates / Proposals for Bids and monitor project progress through the entire project.</p>
              <p>No matter how big or small your team is, there is no need to worry about bidding project and keeping track of project activitiesNo matter how big or small your team is, the Bizns Software Tool will take all worries away concerning the bidding process and keep track of all project activities.</p>
              <p>And when it’s time to send RFI, Change Order or Submittals for the project, you can use the Bizns Tool to create online forms with easily editable templates that are customized for subcontracting business needs.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 team-v7-img">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/saleopp.jpg" alt="Manage Bid Invites from General Contractor" />
      </div>
    </div>
    <!--  End Team Blcoks  -->
  </div>
</div>

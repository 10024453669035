<div>
  <div class="container-fluid">
    <div class="row home-lightblock margin-top-20">
      <div class="col-sm-offset-3 col-sm-6 margin-top-20 text-center">
        <div class="row margin-bottom-40">
          <h4>Invoices (AIA) & Payments</h4>
          <h1>Manage Project Invoices with Bizns Tool</h1>
          <p>Mutiple Invoicing Options Simple Invoicing and AIA Styled (G-702 and G-703)</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">


    <div class="row margin-bottom-20">
      <div class="col-md-4">
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="service">

            <div class="desc">
              <h4 class="color-dark-blue">Invoice (AIA)</h4>
              <p>
                You never have to worry about missing payments again. The Bizns Software Tool enables you to closely track all invoices.
              </p>
              <p>It also makes recording the amounts paid for the project seamless. With QuickBooks integration, recording and tracking invoices have never been easier. </p>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="service">

            <div class="desc">
              <h4 class="color-dark-blue">Payments</h4>
              <p>Payments and invoicing are essentially required to enable a solid interaction with the customer.</p>
              <p>The invoicing tool is provided by Binz Tool. It works efficiently for the projects and financial reporting of the projects.</p>
              <p>Look up all your payments for the project, keep track of amounts paid, and the remaining amounts that are yet to be paid for the project.</p>
            </div>

          </div>
        </div>

      </div>
      <div class="col-md-4">
      </div>

    </div>

    <div class="row">
      <div class="col-sm-offset-3 col-sm-6">
        <img class="img-responsive full-width equal-height-column" src="/assets/images/bizns/invoices.jpg" alt="Create Estimates and Invoices with Customer Relationship Management" />
      </div>
    </div>
  </div>
</div>
